import { merge } from "theme-ui"
import { funk } from "@theme-ui/preset-funk"

const darkTheme = {
  ...funk,
  colors: {
    ...funk.colors,
    divider: "#424245",
    text: "#cccccc",
    secondaryText: "#8A8A8E",
    primary: "#cccccc",
    secondary: `#ff6347`,
    heading: "#eeeeee",
    toggleIcon: "#d2cece",
    background: "#0d1117",
    backgroundColor: "rgba(37, 37, 38)",
    border: "1px solid #333336",
  },
}

const lightTheme = {
  ...funk,
  colors: {
    ...funk.colors,
    text: "#2c2c2c",
    secondaryText: "#8A8A8E",
    background: "rgb(255, 255, 255)",
    backgroundColor: "rgba(244, 244, 244, 0.95)",
    border: "1px solid rgba(244, 244, 244, 0.90)",
    primary: "#000",
    secondary: "#ff6347",
    heading: "#3e3e3e",
    toggleIcon: "#000",
    divider: "#2c2c2c",
  },
}

const theme = merge(lightTheme, {
  initialColorModeName: "light",
  config: {
    useCustomProperties: true,
  },
  colors: {
    modes: {
      dark: darkTheme.colors,
    },
  },
  hero: {
    h1: {
      variant: `cards.h1`,
      fontSize: [5],
    },
    h2: {
      variant: `cards.h2`,
      fontSize: [4],
    },
    p: {
      variant: `p`,
      fontSize: [1, 1, 1, 1],
    },
    card: {
      variant: `cards.fullRoundOutline`,
    },
  },
  cards: {
    h1: {
      variant: `styles.h1`,
      fontSize: [5],
    },
    h2: {
      variant: `styles.h2`,
      fontSize: [4],
    },
    p: {
      variant: `p`,
      fontSize: [1],
    },
    outline: {
      borderColor: "border",
      backgroundColor: "backgroundColor",
    },
    rightRoundOutline: {
      variant: "cards.outline",
      borderTopRightRadius: [8],
      borderBottomRightRadius: [8],
    },
    fullRoundOutline: {
      variant: "cards.outline",
      borderRadius: [8],
    },
  },
  postHeader: {
    backgroundColor: "backgroundColor",
    p: {
      fontSize: [3],
    },
    marginLeft: ["0", "2rem", null, null, "2rem"],
  },
  postHeaderContent: {
    margin: "0 auto",
    maxWidth: `50rem`,
    padding: ["1rem 2rem", null, null, "1rem 1rem"],
  },
  wrapper: {
    global: {
      margin: ["0", "0 0 0 14rem", "0 0 0 14rem", "0 0 0 14rem"],
      maxWidth: `100%`,
      padding: `0`,
    },
    home: {
      margin: "0 auto",
      maxWidth: `50rem`,
      padding: ["0 2rem", "0 2rem", null, null],
    },
  },
  styles: {
    root: {
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      hr: {
        color: `divider`,
        mx: 0,
        borderBottom: 0,
      },
      header: {
        height: `100%`,
        minWidth: `225px`,
      },
      footer: {
        fontSize: [0],
        mt: `3rem`,
        borderTop: `0.5px solid var(--theme-ui-colors-divider)`,
        padding: `1rem 0`,
        ml: `2rem`,
      },
      nav: {
        a: {
          variant: `links.nav`,
        },
        fontSize: [1],
      },
      a: {
        variant: `links.secondary`,
      },
      p: {
        variant: `p`,
      },
    },
    a: {
      color: `primary`,
    },
    p: {
      variant: `p`,
    },
    h1: {
      variant: `text.heading`,
      fontSize: [8],
      pt: `2rem`,
      mb: `1rem`,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [7],
      pt: `1rem`,
      mb: `1rem`,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [6],
      pt: `1rem`,
      mb: `1rem`,
    },
    h4: {
      variant: `text.heading`,
      fontSize: [5],
      pt: `1rem`,
      mb: `1rem`,
    },
    h5: {
      variant: `text.heading`,
      fontSize: [4],
      pt: `1rem`,
      mb: `1rem`,
    },
    h6: {
      variant: `text.heading`,
      fontSize: [3],
      pt: `1rem`,
      mb: `1rem`,
    },
    blockquote: {
      borderLeftColor: `primary`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `6px`,
      mx: 0,
      pl: 4,
      p: {
        fontStyle: `italic`,
      },
    },
    ol: {
      variant: `styles.ul`,
    },
    ul: {
      pl: `2rem`,
      ml: `0.1rem`,
      mr: `0`,
      listStylePosition: `outside`,
      listStyleImage: `none`,
      li: {
        lineHeight: `heading`,
        fontSize: [1],
      },
    },
  },
  fonts: {
    body: `charter, Georgia, Cambria, "Times New Roman", Times, serif`,
    heading: `sohne, "Helvetica Neue", Helvetica, Arial, sans-serif`,
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 800,
  },
  lineHeights: {
    body: 1.9,
    heading: 1.5,
  },
  letterSpacing: {
    heading: `normal`,
    body: `normal`,
  },
  fontSizes: [
    `1.0rem`,
    `1.25rem`,
    `1.50rem`,
    `1.75rem`,
    `2.0rem`,
    `2.25rem`,
    `2.50rem`,
    `2.75rem`,
    `3.0rem`,
  ],
  breakpoints: [
    "40em",
    "52em",
    "64em",
    "76em",
    "88em",
    "100em",
    "112em",
    "124em",
    "136em",
    "148em",
    "160em",
  ],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    heading: {
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
      letterSpacing: `heading`,
      color: `inherit`,
    },
    body: {
      fontFamily: `body`,
      fontWeight: `body`,
      lineHeight: `body`,
      letterSpacing: `body`,
      color: `text`,
    },
  },
  p: {
    fontSize: [1],
    lineHeight: `body`,
    "--baselineMultiplier": 0.179,
    "--xHeightMultiplier": 0.35,
    mt: `0rem`,
    mr: `0rem`,
    mb: `1.5rem`,
    ml: `0rem`,
    p: `0rem`,
  },
  links: {
    secondary: {
      color: `primary`,
      textDecoration: `none`,
      ":hover": {
        color: `heading`,
        textDecoration: `underline`,
      },
      ":focus": {
        color: `heading`,
      },
    },
    text: {
      color: `inherit`,
      textDecoration: `none`,
      ":hover": {
        color: `inherit`,
        textDecoration: `none`,
      },
    },
    heading: {
      fontSize: [3],
      color: `text`,
      textDecoration: `none`,
      ":hover": {
        color: `primary`,
        textDecoration: `underline`,
      },
      ":focus": {
        color: `heading`,
      },
    },
    listItem: {
      fontSize: [1, 2],
      color: `text`,
    },
    nav: {
      color: `text`,
      textDecoration: `none`,
      ":hover": {
        color: `primary`,
        textDecoration: `underline`,
      },
      ":focus": {
        color: `heading`,
      },
    },
  },
})

export default theme
